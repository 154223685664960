import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import axios from "axios";

declare global {
    interface Window {
        grecaptcha: any
    }
}

const SITE_KEY = process.env.SITE_KEY;

function entreMinMax(val: number, min: number, max: number) {
    if (val >= max) {
        return max;
    } else if (val <= min) {
        return min;
    }
    return val;
}

export function App() {
    const [sorteios, setSorteios] = useState(1);
    const [intervalo, setIntervalo] = useState<number[]>([1, 100]);
    const [resultado, setResultado] = useState<number[]>([]);
    const [erro, setErro] = useState('');
    const [carregando, setCarregando] = useState(false);

    const MAX_SORTEIOS = 100;
    const MIN_SORTEIOS = 1;
    const MAX_INTERVALO = 1000000;
    const MIN_INTERVALO = 1;

    useEffect(() => {
        const loadScript = (id: string, url: string, callback?: () => void) => {
            const isLoaded = document.getElementById(id);
            if (!isLoaded) {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = url;
                script.id = id;
                script.onload = () => {
                    if (callback) callback();
                }
                document.body.appendChild(script);
            }

            if (isLoaded && callback) callback();
        };

        loadScript('grecaptcha-script', `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`);
    }, []);

    function handleInputSorteios(evt: ChangeEvent<HTMLInputElement>) {
        const value = evt.target.value;
        if (value || value === '0') {
            let n = parseInt(value);
            if (n) {
                setSorteios(entreMinMax(n, MIN_SORTEIOS, MAX_SORTEIOS));
            }
        } else {
            setSorteios(undefined);
        }
    }

    function handleInputIntervaloInicio(evt: ChangeEvent<HTMLInputElement>) {
        const value = evt.target.value;
        if (value || value === '0') {
            let n = parseInt(value);
            if (n) {
                const max = (intervalo[1] - 1) | 1;
                setIntervalo([entreMinMax(n, MIN_INTERVALO, max), intervalo[1]]);
            }
        } else {
            setIntervalo([undefined, intervalo[1]]);
        }
    }

    function handleInputIntervaloFim(evt: ChangeEvent<HTMLInputElement>) {
        const value = evt.target.value;
        if (value || value === '0') {
            let n = parseInt(value);
            if (n) {
                setIntervalo([intervalo[0], entreMinMax(n, MIN_INTERVALO, MAX_INTERVALO)]);
            }
        } else {
            setIntervalo([intervalo[0], undefined]);
        }
    }

    function handleButtonClick(e: MouseEvent<HTMLButtonElement>) {
        setErro('');
        e.preventDefault();
        window.grecaptcha.ready(async () => {
            setCarregando(true);
            try {
                const token = await window.grecaptcha.execute(SITE_KEY, { action: 'submit' });
                await sortear(token);
            } catch (err) {
                setErro('Tivemos um problema inesperado, por favor atualize a página e tente novamente.');
            } finally {
                setCarregando(false);
            }
        });
    }

    async function sortear(token: string) {
        const resp = await axios.post('/.netlify/functions/sorteio', { sorteios: sorteios, intervalo: intervalo, token: token });
        if (resp.status === 200) {
            setResultado(resp.data.resultado);
        }
    }

    function formInvalido(): boolean {
        if (!sorteios || !intervalo[0] || !intervalo[1]) {
            return true;
        }
        const tamanho = intervalo[1] - intervalo[0];
        if (sorteios > tamanho) {
            return true;
        }
        return false;
    }

    let disableButton = formInvalido();
    if (carregando) {
        disableButton = true;
    }

    let displayErro = null;
    if (erro) {
        displayErro = (
            <div key="erro" className="error">
                {erro}
            </div>
        );
    }

    return (
        <>
            <header>
                <h1>Sorteio</h1>
            </header>
            <main>
                <div className="form-container">
                    <div>
                        <label htmlFor="quantidade">Quantidade</label> &nbsp;
                        <input id="quantidade" type={"number"} value={sorteios} onChange={handleInputSorteios} />
                    </div>
                    <div>
                        Número entre &nbsp;
                        <input type={"number"} value={intervalo[0]} onChange={handleInputIntervaloInicio} />
                        &nbsp;e&nbsp;
                        <input type={"number"} value={intervalo[1]} onChange={handleInputIntervaloFim} />
                    </div>
                    <div>
                        <button onClick={handleButtonClick} disabled={disableButton} >Sortear</button>
                    </div>
                    {displayErro}
                    <div>
                        {resultado.length ? <h2>Resultado do Sorteio</h2> : ''}
                        {resultado.join(', ')}
                    </div>
                </div>
            </main>
        </>
    );
}