import React = require("react");
import { createRoot } from "react-dom/client";
import { App } from "./app";
import './style.css';


const app = document.getElementById("app");
createRoot(app).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);